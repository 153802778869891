:root {
    --color-secondary: #283146;
    --color-secondary-light: #2d374f;
    --color-secondary-transparent: #2e354657;
    --color-dots: #767a8f;
    --drag-over-color: #D8DFE9;
    --drag-over-background: #065bca15;
    --color-input-background: #22293a57;
    --color-input-border: #3d4557;
    --color-font: #ebeff4;
    --color-font-light: #c5ccd5;
    --color-primary: #2288E5;
    --color-primary-light: #2287e521;
    --color-primary-white: #96cafc;
    --color-primary-dark: #1a487d;
    --color-accent: #0c84db;
    --color-accent-light: #0e8dd71c;
    --color-tirtiary: #ffa600;
    --color-tirtiary-light: #ffa60027;
    --color-error: #f15609;
    --color-error-light: #732e0b;
    --color-error-transparent: #f1560915;
    --color-border: #3b4456;
    --popup-background: rgba(54, 55, 73, 0.411);
    --smooth-shadow: 0 10px 16px rgba(11, 13, 19, 0.623);
    --color-dark-background: #060A0E;
    --color-dark-background-transparent: #060a0e44;
    --color-dark-background-skeleton: #222f3b;
    --color-dark-background-light: #161f27;


    --smooth-shadow: 0px 5px 20px rgba(37, 38, 56, 0.1);

}
