@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Inter:wght@400;500&display=swap');

body {
  font-size: 14px;
  letter-spacing: 0.01em;
  font-weight: 400px;
  line-height: 1.5em;
  // font-family: 'Inter', sans-serif;
  // font-family: "Noto Sans", sans-serif;
  font-family: "Inter", sans-serif;
  color: var(--color-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  p,
  pre,
  label {
    padding: 0;
    margin: 0;
    font-weight: 400;
    color: var(--color-font);
    font-family: "Inter", sans-serif;
  }

  a {
    text-decoration: none;
    outline: none;
    &:focus {
      outline: none;
    }
  }

  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
